.page6 #main-header-area {
    padding: 40px 0px;
    position: relative;
}
.page6 .mainBodyLeft {
    position: absolute;
    top: -10%;
    left: 0%;
    z-index: -1;
}
.page6 .bodyTopEffect {
    position: absolute;
    width: 352px;
    height: 352px;
    right: 0%;
    top: -73px;
    background: #00c9c9;
    -webkit-filter: blur(400px);
    filter: blur(400px);
    z-index: -1;
    opacity: 0.7;
}
.page6 .about-us-left-area {
    position: relative;
}
.page6 .aboutUsEffect {
    position: absolute;
    width: 552px;
    height: 552px;
    left: -60%;
    top: 0%;
    background: #4583ff;
    -webkit-filter: blur(400px);
    filter: blur(400px);
    z-index: -1;
    opacity: 0.8;
}
.page6 #main-story-area {
    position: relative;
    background-color: #fff;
}
.page6 .storyBodyEffect {
    position: absolute;
    width: 400px;
    height: 400px;
    left: 40%;
    bottom: -10%;
    background: #00c9c9;
    -webkit-filter: blur(200px);
    filter: blur(200px);
    z-index: -1;
}
.page6 .storyBodyEffectUp {
    position: absolute;
    top: 0%;
    right: 0%;
}
.page6 #main-shop-area {
    position: relative;
}
.page6 .shopBodyEffect {
    position: absolute;
    width: 423px;
    height: 423px;
    left: 35%;
    top: 0%;
    z-index: -1;
    background: #a95bdd;
    -webkit-filter: blur(400px);
    filter: blur(400px);
    opacity: 0.7;
}
.page6 .newsletter-detalis {
    position: relative;
}
.page6 .newsLetterEffectUp {
    position: absolute;
    top: -67%;
    left: -1%;
}
.page6 .newsLetterEffectDown {
    position: absolute;
    bottom: -38%;
    right: -1%;
}
.page6 #main-body-section {
    position: relative;
}
.page6 .profileBodyLeftEffect {
    position: absolute;
    top: -20%;
    left: 0%;
    z-index: -1;
}
.page6 .bodyEffectBlueHere {
    position: absolute;
    width: 352px;
    height: 352px;
    right: 0%;
    top: 0%;
    z-index: -1;
    opacity: 0.7;
    background: #00c9c9;
    -webkit-filter: blur(400px);
    filter: blur(400px);
}
.page6 .bodyEffectRedHere {
    position: absolute;
    width: 352px;
    height: 352px;
    left: 50%;
    top: 60%;
    z-index: -1;
    opacity: 0.4;
    background: #4583ff;
    -webkit-filter: blur(400px);
    filter: blur(400px);
}
