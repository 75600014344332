.yourAnalysisHeading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.yourAnalysisTitle {
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #040814;
}

.yourAnalysisArrows {
	display: flex;
	gap: 15px;
}

.arrow {
	cursor: pointer;
}

.card {
	color: #040814;
	height: 140px;
	background-color: white;
	padding: 10px;
	display: flex;
	justify-content: center;
	border-radius: 10px;
}

.cardImage {
	height: 100%;
	border-radius: 10px;
}

.names {
	position: absolute;
	z-index: 30;
	bottom: 0px;
	left: 0px;
	right: 0;
	height: 100px;
	background: linear-gradient(to bottom, transparent, 70%, rgb(39, 39, 39));
}

.cardName {
	bottom: 5px;
	font-weight: 600;
	font-size: 12px;
	/* line-height: 24px; */
	margin-bottom: 4px;
	color: #ffffff;
	padding: 0px 2px;
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	width: 80%;
}

.cardTitle {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
}

.SlideComponent {}

.tooltip {
	margin-top: 10px;
	width: 100%;
	background: #00c9c9;
	color: white;
	text-align: left;
	border-radius: 26px;
	font-size: 12px;
	padding: 15px;
	left: 0;
	position: absolute;
	z-index: 10;
	text-align: center;
	animation: appear-from-top 0.5s both;
}

.tooltip p {
	text-decoration: underline;
	font-weight: 600;
	margin-bottom: 8px;
}

.percentage {
	position: absolute;
	top: 2px;
	left: 2px;
	background: #00c9c9;
	font-size: 12px;
	color: white;
	padding: 3px 5px;
	border-radius: 10px;
	z-index: 10;
}

@keyframes appear-from-top {
	0% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}