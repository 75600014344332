.heading {
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  color: #040814;
  margin-bottom: 8px;
}
.sub_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #171b26;
  margin-bottom: 24px;
}
.children_form_section {
  padding: 64px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  background: #ffffff;
  border-radius: 16px;
  width: 590px;
}

.children_form_label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #171b26;
  margin-bottom: 8px;
}
.children_form_input {
  background: #ffffff;
  border: 1px solid #e3e9f2;
  border-radius: 50px;
  padding: 12px 24px;
}
.children_form_input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #828ca5;
}
.password {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.password img {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.gender {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #171b26;
}
.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px 0;
}
.profile li {
  cursor: pointer;
  margin-top: -10px;
}
.profile label {
  border-radius: 50%;
  width: 63px;
  height: 63px;
  background: #f4f6f9;
  display: grid;
  place-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.profile label:hover {
  background: #ffffff;
  border: 3px solid #00c9c9;
}
.profile p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #171b26;
  margin-top: 4px;
}

[type="radio"] {
  opacity: 0;
}
[type="radio"]:checked ~ label {
  background: #ffffff;
  border: 3px solid #00c9c9;
}
.register_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  gap: 8px;
  border: none;
  background: linear-gradient(90deg, #00c9c9 0%, #5a85e0 100%);
  border-radius: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;
}
.register_with {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #828ca5;
  margin: 0 5px;
}
.register_with:before,
.register_with:after {
  content: "";
  flex: 1 1;
  border: 1px solid #e3e9f2;
  margin: auto;
}
.social_login {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.social_login_icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  width: 36px;
  height: 36px;
  background: #00c9c9;
  border-radius: 50%;
  cursor: pointer;
}
.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.have_an_account {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #171b26;
}
.login_link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00c9c9;
}
.login_link:hover {
  color: #00c9c9;
}
@media (max-width: 768px) {
  .children_form_section {
    width: 100%;
  }
  .children_form_section,
  .form_gap {
    gap: 14px 0;
  }
}
@media (max-width: 576px) {
  .heading {
    font-size: 24px;
  }

  .sub_title,
  .children_form_label,
  .children_form_input,
  .gender,
  .profile p,
  .register_btn,
  .register_with,
  .have_an_account,
  .login_link {
    font-size: 14px;
  }
  .children_form_section {
    padding: 32px 20px;
  }
}