.goalHeading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.goalTitle {
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #ffffff;
}

.goalArrows {
	display: flex;
	gap: 15px;
	margin-right: 3rem;
}

.arrow {
	cursor: pointer;
}

.card {
	background: radial-gradient(56.02% 61.73% at 5% 0%, #a3ffbc 0%, #00c9c9 100%);
	box-shadow: 0px 8px 16px rgb(129 11 108 / 32%);
	border-radius: 8px;
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
	/* border-radius: 8px;
	padding-top: 35px;
	padding-bottom: 35px; */
}

.cardName {
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	text-align: center;
	/* padding-top: 70px; */
}

@media (max-width: 768px) {
	.goalTitle {
		font-size: 16px;
		line-height: 24px;
	}

	.goalArrows {
		display: none;
	}
}