.yourAnalysisArrows {
    display: flex;
    gap: 15px;
    justify-content: space-evenly;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.filterTitle {
    font-weight: 600;
    font-size: 20px;
}

.collage {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
}

.collageItem {
    padding: 4px;
    position: relative;
}

.collageItem img {
    width: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.collageItem p {
    background: #00c9c9;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    color: white;
    /* position: absolute; */
}