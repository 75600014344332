.mainTitleText {
    background: linear-gradient(100.82deg, #FFA48F 1.32%, #FF4793 21.18%, #A65CE0 48.04%, #9167E0 55.28%, #5986E0 74.97%, #00C9C9 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subscribe {
    /* margin-left: -200px;
    margin-right: -200px;*/
    margin-top: -42px;
    height: 148px;
    /* position: absolute; */
}

.requestADemo svg {
    height: 250px !important;
    margin-top: -60px;
    margin-bottom: -60px;
    /* margin-left: -90px; */
}

.getYourHairAnalysisRef {
    /* width: 60px;
    min-height: 70px; */
    /* margin-top: -220px;
    margin-bottom: -230px;
    margin-left: -100px; */
    margin-top: -40px;
    margin-bottom: -80px;
}

@media screen and (max-width: 992px) {
    /* .newsLetterForm{
        display: flex;
        flex-direction: column;
    } */
    /* .subscribe{
        margin-left: auto;
        margin-right: auto;
        margin-top: -150px;
        width: 400px;
        margin-bottom: -100px;
        position: relative;
    } */
}